import { getUserRole, Roles } from '@/components/routing/roles';
import { TableSection } from '@/components/shared/Table/core/Components';

export const clientColumnKey = 15;

export const tableSections: TableSection[] =
  [
    {
      name: 'BOW',
      columns: [
        {
          title: 'BOW ID',
          format: 'label',
          propertyName: 'InternalReference',
          parameterName: 'SelectedInternalReferences'
        },
        {
          title: 'Client ref.',
          format: 'text',
          propertyName: 'ClientReference',
          parameterName: 'SelectedClientReferences'
        },
        {
          title: 'Status',
          format: 'label',
          propertyName: 'Status',
          parameterName: 'SelectedStatus',
          noDropdown: true
        },
        {
          title: 'Manual Override',
          format: 'char',
          propertyName: 'Overridden',
          parameterName: 'selectedOverridden'
        },
        {
          title: 'Client',
          key: clientColumnKey,
          format: 'text',
          propertyName: 'ClientName',
          parameterName: 'SelectedClientNames'
        },
        {
          title: 'Fleet',
          format: 'text',
          propertyName: 'FleetName',
          parameterName: 'SelectedFleets'
        },
        {
          title: 'Vessel',
          format: 'text',
          propertyName: 'VesselName',
          parameterName: 'SelectedVessels'
        },
        {
          title: 'Type',
          format: 'text',
          propertyName: 'VesselType',
          parameterName: 'SelectedVesselTypes'
        },
      ]
    },
    {
      name: 'Location',
      columns: [
        {
          title: 'Covered area',
          format: 'text',
          propertyName: 'CoveredAreas',
          parameterName: 'SelectedCoveredAreas'
        },
        {
          title: 'Port',
          format: 'text',
          propertyName: 'Ports',
          parameterName: 'SelectedPorts'
        },
      ]
    },
    {
      name: 'Time',
      columns: [
        {
          title: 'Entry',
          format: 'label',
          propertyName: 'Entry',
          parameterName: 'Entry',
          type: 'Date'
        },
        {
          title: 'Exit',
          format: 'label',
          propertyName: 'Exit',
          parameterName: 'Exit',
          type: 'Date'
        },
        {
          title: 'Days covered',
          format: 'label',
          propertyName: 'DaysCovered'
        }
      ]
    },
    {
      columns: [
        {
          title: 'Last updated',
          format: 'label',
          propertyName: 'LastUpdated'
        },
        {
          title: 'By',
          format: 'char',
          propertyName: 'LastUpdatedBy',
          parameterName: 'SelectedLastUpdatedBy',
          dropdownAlign: 'right'
        },
        { title: '', key: 1 }
      ]
    }
  ];

const brokerSkips = ['Overridden'];
const businessOperationsSkips = ['Overridden'];
const reinsurerSkips = ['DaysCovered', 'Overridden'];

const filterSections = (skip: string[]) =>
  tableSections.map(s => ({
    ...s,
    columns: s.columns.filter(h => !skip.includes(h.propertyName ?? ''))
  }));

export const getSections = () => {
  switch (getUserRole()) {
    case Roles.broker:
      return filterSections(brokerSkips);
    case Roles.businessOperations:
      return filterSections(businessOperationsSkips);
    case Roles.reinsurer:
      return filterSections(reinsurerSkips);
    default:
      return tableSections;
  }
};

import { PageHeader } from '@/components/layout/PageHeader';
import {
  isAdmin,
  isBroker,
  isBusinessOperations,
  isInternalUser,
  isReinsurer,
  isUnderwriter,
} from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { BowsBox } from '@/components/shared/Bow/core/BowsBox';
import { SectionDropdown } from '@/components/shared/Bow/core/SectionDropdown';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { FlexBox } from '@/components/shared/FlexBox';
import { ButtonItem, DropdownButton } from '@/components/shared/Form/fields/DropdownButton';
import { getHeaders } from '@/components/shared/Table/core/Components';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import useDebounce from '@/hooks/useDebouncedValue';
import { useQuery } from '@/hooks/useQuery';
import { Filters } from '@/models/Filters';
import { toFilter } from '@/models/SortOrder';
import { basePathBows } from '@/services/bowServices';
import { MediaType } from '@/services/config';
import { useShowClients } from '@/services/meService';
import { downloadSearchData } from '@/utility/downloadHelper';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Add, Download } from '@instech/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { clientColumnKey, getSections } from './core/headers';

const BowPageContainer = styled.div``;

const StyledFlexBox = styled(FlexBox)`
  gap: 2em;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownWrapper = styled(FlexBox)`
  width: 200px;
`;

const DropdownButtonWrapper = styled(FlexBox)`
  margin-left: 1rem;
  width: 250px;
`;

const defaultFilters = {
  pageNumber: '1',
  pageSize: '15',
  SelectedStatus: ['Draft', 'Quoted', 'PendingConfirmation', 'Confirmed'],
  orderBy: 'LastUpdated desc',
};

const buttonSelections: ButtonItem[] = [
  {
    id: '1',
    text: 'Create New Bow',
    to: Routes.createbow,
    background: theme.green,
    icon: <Add />,
  },
  {
    id: '2',
    text: 'Create Non Rate Lead Bow',
    to: `${Routes.createbow}?NonRateLead`,
    background: theme.white,
    icon: <Add />,
  },
];

export const BowPage = () => {
  const { pageSettings, setPageSettings, setPageKey } = usePageSettingsContext();
  const showClients = useShowClients();

  const [underwriterSection, setUnderwriterSection] = useState<WarWeb.Section>();
  const shouldSeeSectionDropdown = isInternalUser();

  useEffect(() => {
    setPageKey('bows');
    return () => setPageKey();
  }, []);

  const restoredFilters = pageSettings?.bows?.filters ?? {};

  const query = useQuery();
  const selectedFleetParam = [query.get('SelectedFleets')];
  const selectedVesselsParam = [query.get('SelectedVessels')];
  const selectedPortsParam = [query.get('SelectedPorts')];

  const restoredSorting = toFilter(restoredFilters?.orderBy);

  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
    ...restoredFilters,
    ...(selectedFleetParam.some(x => Boolean(x)) && { SelectedFleets: selectedFleetParam }),
    ...(selectedVesselsParam.some(x => Boolean(x)) && { SelectedVessels: selectedVesselsParam }),
    ...(selectedPortsParam.some(x => Boolean(x)) && { SelectedPorts: selectedPortsParam }),
  });

  const debouncedFilters = useDebounce(filters, 500);

  useEffect(() => {
    setPageSettings({
      bows: {
        ...pageSettings?.bows,
        filters,
      },
    });
  }, [filters]);

  const headers = getHeaders(getSections(), !showClients ? h => h.key !== clientColumnKey : undefined);

  const downloadButtonSelections: ButtonItem[] = [
    {
      id: '1',
      text: 'Download',
      onClick: async () => {
        await downloadSearchData(`${basePathBows}/search`, debouncedFilters, MediaType.Excel);
      },
      background: theme.green,
      icon: <Add />,
    },
    {
      id: '2',
      text: 'Exposure',
      onClick: async () => {
        await downloadSearchData('export/exposure', debouncedFilters, MediaType.Excel);
      },
      background: theme.white,
      icon: <Add />,
    },
  ];

  const canSeeExposureDownloadButton = isUnderwriter() || isAdmin() || isReinsurer();

  return (
    <BowPageContainer>
      <HeaderContainer>
        <StyledFlexBox alignItems="center">
          <PageHeader header="Breach of Warranty" />

          {shouldSeeSectionDropdown && (
            <DropdownWrapper>
              <SectionDropdown underwriterSection={underwriterSection} setUnderwriterSection={setUnderwriterSection} />
            </DropdownWrapper>
          )}
        </StyledFlexBox>

        <ButtonGroup>
          {canSeeExposureDownloadButton ? (
            <DropdownButtonWrapper>
              <DropdownButton options={downloadButtonSelections} />
            </DropdownButtonWrapper>
          ) : (
            <ButtonWithLoader
              onClick={() => downloadSearchData(`${basePathBows}/search`, debouncedFilters, MediaType.Excel)}
              background={theme.white}
              icon={<Download />}
            >
              Download
            </ButtonWithLoader>
          )}
          {!isBusinessOperations() && !isReinsurer() && (
            <>
              {isBroker() ? (
                <Button to={Routes.createbow} background={theme.green} icon={<Add />}>
                  New bow
                </Button>
              ) : (
                <DropdownButtonWrapper>
                  <DropdownButton options={buttonSelections} />
                </DropdownButtonWrapper>
              )}
            </>
          )}
        </ButtonGroup>
      </HeaderContainer>

      <BowsBox
        config={{
          statusButtons: true,
          filterButtons: true,
          paging: true,
          resizeColumns: true,
        }}
        headers={headers}
        initialFilters={{
          ...defaultFilters,
          ...(shouldSeeSectionDropdown && { selectedSectionCode: underwriterSection?.code }),
        }}
        filters={filters}
        setFilters={setFilters}
        initialSorting={restoredSorting || toFilter(defaultFilters.orderBy)}
      />
    </BowPageContainer>
  );
};

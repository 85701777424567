import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { Filters } from '@/models/Filters';
import { postFetcher } from './utility/simpleFetch';

const getFacetsUri = (basePath: string, parameterName: string, filter: string, key?: string) =>
  `${basePath}/metadata/columns/${parameterName}/facets?filter=${filter}&maxCount=1000&key=${key}`;

const prepareFilters = (filters: Filters) =>
  ({ ...filters, selectedOverridden: filters.selectedOverridden?.map((it: string) => it === 'True') });

export const useFacets = (load: boolean, basePath: string, filter: string, parameterName: string, params: Filters, key?: string) => {
  const facetsUri = getFacetsUri(basePath, parameterName, filter, key);

  const filters = prepareFilters(params);
  return useSWREndpoint<WarWeb.FacetsSearchResult>(
    load ? facetsUri : null,
    undefined,
    () => postFetcher<WarWeb.FacetsSearchResult>(facetsUri, filters)
  );
};

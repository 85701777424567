import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import Qs from 'qs';
import { exposureOverviewMock } from '@/mockData/services/bowServicesMocks';
import { BowMatch } from '@/models/BowMatch';
import { baseFilters } from '@/components/pages/dashboard/baseFilters';
import { TabKey } from '@/components/pages/dashboard/dashboardTabs';
import { Filters } from '@/models/Filters';
import { AcceptHeaders } from './config';
import { postFetcher } from './utility/simpleFetch';

const toBowSearchParameters = (filters: Filters): WarWeb.BowSearchParameters =>
  ({ ...filters, selectedOverridden: filters.selectedOverridden?.map((it: string) => it === 'True') });

export const basePathBows = 'bows';
const basePathExposure = `${basePathBows}/exposure`;
export const getBowQuotePath = (bowId: string, quoteId: string) => `${bowId}/quotes/${quoteId}`;

export const bowUri = (id: string) => `${basePathBows}/${id}`;

export const useBow = (id?: string) => useSWREndpoint<WarWeb.Bow>(id ? bowUri(id) : null);

export const useBows = (params?: Filters, key?: string) =>
  useSWREndpoint<WarWeb.SearchResult<BowMatch>>(
    params ? `${basePathBows}/search?key=${key}` : null,
    undefined,
    () => postFetcher(`${basePathBows}/search`, toBowSearchParameters(params!), AcceptHeaders.SearchResult)
  );

// EXPOSURE
const getExposureUri = (params: any) => `${basePathExposure}?${Qs.stringify(params)}`;
const exposureOverviewUri = `${basePathExposure}/overview`;

export const useExposure = (params: any) => useSWREndpoint(getExposureUri(params), AcceptHeaders.SearchResult);

const exposureOverviewFetcher = async () => exposureOverviewMock;

export const useExposureOverview = () => useSWREndpoint<any>(
  exposureOverviewUri,
  undefined,
  () => exposureOverviewFetcher()
);

const useBowCount = (params: WarWeb.BowSearchParameters, key?: string) => {
  const { data, isLoading } = useBows({ ...params, pageSize: 1 }, key);
  return { count: data?.pagingMetadata.totalCount, isLoading };
};

export interface BowCountsProps {
  count: number[];
  isLoading: boolean;
}
export const useBowCounts = (filters?: Filters) : [Record<TabKey, BowCountsProps>, boolean] => {
  const { pageNumber, ...restFilters } = filters || {};

  const { count: redCount, isLoading: isLoadingRed }
  = useBowCount({ ...baseFilters.red[0], ...restFilters }, JSON.stringify({ ...baseFilters.red[0], ...restFilters, tab: 'red' }));

  const { count: yellowCount, isLoading: isLoadingYellow }
  = useBowCount({ ...baseFilters.yellow[0], ...restFilters }, JSON.stringify({ ...baseFilters.yellow[0], ...restFilters, tab: 'yellow' }));

  const { count: greyCount, isLoading: isLoadingGrey }
  = useBowCount({ ...baseFilters.grey[0], ...restFilters }, JSON.stringify({ ...baseFilters.grey[0], ...restFilters, tab: 'grey' }));

  const { count: blueCount, isLoading: isLoadingBlue }
  = useBowCount({ ...baseFilters.blue[0], ...restFilters }, JSON.stringify({ ...baseFilters.blue[0], ...restFilters, tab: 'blue' }));

  const { count: greenCount1, isLoading: isLoadingGreen1 }
  = useBowCount({ ...baseFilters.green[0], ...restFilters }, JSON.stringify({ ...baseFilters.green[0], ...restFilters, tab: 'green-1' }));

  const { count: greenCount2, isLoading: isLoadingGreen2 }
  = useBowCount({ ...baseFilters.green[1], ...restFilters }, JSON.stringify({ ...baseFilters.green[1], ...restFilters, tab: 'green-2' }));

  const isLoading = isLoadingRed || isLoadingYellow || isLoadingGrey || isLoadingBlue || isLoadingGreen1 || isLoadingGreen2;

  return [
    {
      red: {
        count: [redCount ?? 0],
        isLoading: isLoadingRed
      },
      yellow: {
        count: [yellowCount ?? 0],
        isLoading: isLoadingYellow
      },
      grey: {
        count: [greyCount ?? 0],
        isLoading: isLoadingGrey
      },
      blue: {
        count: [blueCount ?? 0],
        isLoading: isLoadingBlue
      },
      green: {
        count: [greenCount1 ?? 0, greenCount2 ?? 0],
        isLoading: isLoadingGreen1 || isLoadingGreen2
      }
    },
    isLoading
  ];
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Pin, WarningFilled } from '@instech/icons';
import moment from 'moment';
import {
  FilterableTableHeaders, NoDataRow, TableRow, TableCell, Table
} from '@/components/shared/Table/Table';
import { Routes } from '@/components/routing/routes';
import { basePathBows, useBow } from '@/services/bowServices';
import { bowStatusToObject } from '@/utility/bowStatus';
import {
  getColumnWidth,
  getDelimiters, NameGroup, renderTableSectionHeaders, Tooltip
} from '@/components/shared/Table/core/Components';
import { TimeConverter } from '@/utility/timeConverter';
import { BowMatch } from '@/models/BowMatch';
import { NetworkStatusLoading } from '@/services/NetworkStatusLoading';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { UserInitials } from '@/utility/userNameHelpers';
import { Disabled } from '@/components/shared/LoadingOverlay';
import { formatDate } from '@/utility/dateCalc';
import { clientColumnKey, getSections } from '@/components/pages/bows/core/headers';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import { Globals } from '@/utility/globals';
import { useShowClients } from '@/services/meService';
import { isReinsurer } from '@/components/routing/roles';
import { useFilterableTableHeadersContext } from '@/components/pages/defaultTermsConditions/FilterableTableHeadersContext';
import { openErrorModal } from '@/components/error/ErrorModal';
import { useModalContext } from '@/components/modal/ModalContext';

const TableCellLink = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTableRow = styled(TableRow)`
  .tooltip {
    text-transform: uppercase;
    max-width: 260px;
    white-space: normal;
  }
`;

interface BowRowProps {
  bow: BowMatch,
  index: number,
  open: (id: string) => void
}
const BowRow = ({ bow, index, open }: BowRowProps) => {
  const { headers } = useFilterableTableHeadersContext();
  const [rowFocus, setRowFocus] = useState<boolean>(false);
  const bowStatus = bowStatusToObject(bow.status);

  const showClients = useShowClients();

  const utcNow = moment().utc();
  const utc12HoursInFuture = moment().utc().add(Globals.EntryHoursCheck, 'hours');
  const utc10DaysAgo = moment().utc().subtract(Globals.ExitDaysCheck, 'days');

  const isOverallEntryPastAlert = moment(bow.overallAlertEntry).isBefore(utcNow);
  const isOverallEntryAlert = moment(bow.overallAlertEntry).isBetween(utcNow, utc12HoursInFuture);
  const isOverallExitAlert = moment(bow.overallAlertExit).isBefore(utc10DaysAgo);

  const isPrimaryEntryPastAlert = moment(bow.primaryAlertEntry).isBefore(utcNow);
  const isPrimaryEntryAlert = moment(bow.primaryAlertEntry).isBetween(utcNow, utc12HoursInFuture);
  const isPrimaryExitAlert = moment(bow.primaryAlertExit).isBefore(utc10DaysAgo);

  const overallEntryText = `The bow has an alternative with an entry in less than ${Globals.EntryHoursCheck} hours`;
  const overallExitText = `The bow has an alternative with an exit more than ${Globals.ExitDaysCheck} days ago`;
  const overallEntryPastText = 'The bow has an alternative with an entry that is passed';

  const primaryEntryText = `This bow alternative has an entry in less than ${Globals.EntryHoursCheck} hours`;
  const primaryExitText = `This bow alternative has an exit more than ${Globals.ExitDaysCheck} days ago`;
  const primaryEntryPastText = 'This bow alternative has an entry that is passed';

  const isEven = index % 2;
  const redAlert = isEven ? theme.mediumRed75 : theme.mediumRed50;
  const yellowAlert = isEven ? theme.mediumYellow50 : theme.mediumYellow75;

  let backgroundColorOverall = '';
  let backgroundColorEntry = '';
  let backgroundColorExit = '';
  let tooltipOverAll = '';
  let tooltipEntry = '';

  if (bow.status === 'Quoted') {
    if (isOverallEntryAlert) {
      backgroundColorOverall = yellowAlert;
      tooltipOverAll = overallEntryText;
    }
    if (isOverallExitAlert) {
      backgroundColorOverall = yellowAlert;
      tooltipOverAll = overallExitText;
    }
    if (isPrimaryEntryAlert && !isPrimaryExitAlert) {
      backgroundColorEntry = yellowAlert;
      tooltipEntry = primaryEntryText;
    }
    if (isOverallEntryPastAlert) {
      backgroundColorOverall = yellowAlert;
      tooltipOverAll = overallEntryPastText;
    }
    if (isPrimaryEntryPastAlert && !isPrimaryExitAlert) {
      backgroundColorEntry = yellowAlert;
      tooltipEntry = primaryEntryPastText;
    }
    if (isPrimaryExitAlert) {
      backgroundColorExit = yellowAlert;
    }
    if (isOverallExitAlert) {
      backgroundColorOverall = yellowAlert;
      tooltipOverAll = overallExitText;
    }
    if (isPrimaryExitAlert) {
      backgroundColorExit = yellowAlert;
    }
  }

  if (bow.status === 'Confirmed') {
    if (isOverallExitAlert) {
      backgroundColorOverall = redAlert;
      tooltipOverAll = overallExitText;
    }
    if (isPrimaryExitAlert) {
      backgroundColorExit = redAlert;
    }
  }

  const canOpen = !isReinsurer();

  if ((isOverallEntryAlert && (isOverallEntryPastAlert || isOverallExitAlert)) ||
    (isPrimaryEntryAlert && (isPrimaryEntryPastAlert || isPrimaryExitAlert))) {
    return null;
  }

  const tooltipName = bowStatus?.title === 'PendingConfirmation' ? 'Pending' : bowStatus?.title ?? '';

  const hasHeader = (propertyName: string) => headers.some(header => header.propertyName === propertyName);

  return (
    <StyledTableRow hover={canOpen}
      onClick={canOpen ? () => open(bow.id) : undefined}
      hasFocus={canOpen ? rowFocus : undefined}>
      <TableCell tabIndex={0}
        onKeyDown={e => e.key === 'Enter' && canOpen ? open(bow.id) : undefined}
        onFocus={() => setRowFocus(true)}
        onBlur={() => setRowFocus(false)}
        slim
      >
        <TableCell backgroundColor={backgroundColorOverall}>
          <Tooltip id={bow.id} name={backgroundColorOverall ? tooltipOverAll : undefined}>
            {bow.internalReference}
          </Tooltip>
        </TableCell>
      </TableCell>
      <TableCell>{bow.clientReference}</TableCell>
      <TableCell data-for={`${bow.id}-status`}>
        <Tooltip id={`${bow.id}-status`} name={tooltipName}>
          {bowStatus?.icon}
        </Tooltip>
      </TableCell>
      {hasHeader('Overridden') && (
        <TableCell>
          {bow.overridden && <WarningFilled />}
        </TableCell>
      )}
      {showClients && (
        <TableCell>{bow.clientName}</TableCell>
      )}
      <TableCell>{bow.fleetName}</TableCell>
      <TableCell>{bow.vesselName}</TableCell>
      <TableCell>{bow.vesselType}</TableCell>
      <TableCell delimiter="left">
        <NameGroup values={bow.coveredAreas} />
      </TableCell>
      <TableCell>
        <NameGroup values={bow.ports} />
      </TableCell>
      <TableCell slim>
        <TableCell
          delimiter="left"
          backgroundColor={backgroundColorEntry}
          data-for={`${bow.id}-entry`}
        >
          <Tooltip id={`${bow.id}-entry`} name={backgroundColorEntry ? tooltipEntry : undefined}>
            {formatDate(bow.entry) ?? 'TBD'}
          </Tooltip>
        </TableCell>
      </TableCell>
      <TableCell slim>
        <TableCell
          backgroundColor={backgroundColorExit}
          data-for={`${bow.id}-exit`}
        >
          <Tooltip id={`${bow.id}-exit`} name={backgroundColorExit ? primaryExitText : undefined}>
            {formatDate(bow.exit) ?? 'TBD'}
          </Tooltip>
        </TableCell>
      </TableCell>
      {hasHeader('DaysCovered') && <TableCell>{bow.daysCovered}</TableCell>}
      <TableCell delimiter="left">{TimeConverter.ToDate(bow.lastUpdated)}</TableCell>
      <TableCell><UserInitials name={bow.lastUpdatedBy} /></TableCell>
      <TableCellLink hover onClick={e => e.stopPropagation()}>
        <a tabIndex={-1}
          href={`https://www.marinetraffic.com/en/ais/details/ships/${bow.imoNumber}`}
          target={`_marineTraffic${bow.imoNumber}`}>
          <Pin />
        </a>
      </TableCellLink>
    </StyledTableRow>
  );
};

interface BowTableProps {
  bows?: WarWeb.SearchResult<BowMatch>,
  noHeader?: boolean,
  resizeColumns?: boolean,
  noDataDisplay?: React.ReactNode
  loadStatus: NetworkStatusLoading
}

export const BowTable = ({
  bows,
  loadStatus,
  noDataDisplay,
  noHeader = false,
  resizeColumns = false
}: BowTableProps) => {
  const history = useHistory();
  const { setPageKey } = usePageSettingsContext();
  const { headers } = useFilterableTableHeadersContext();
  const [loadBowId, setLoadBowId] = useState<string>();
  const { open } = useModalContext();

  const sections = getSections();

  const { data, error } = useBow(loadBowId);
  const showClients = useShowClients();

  useEffect(() => {
    if (error) {
      open(openErrorModal(undefined, 'This action is forbidden', false));
      setLoadBowId(undefined);
    }
    if (data) {
      if (data.status === 'Draft' && data?.quotes.length === 1) {
        history.push({
          pathname: Routes.createbow,
          search: `bowId=${loadBowId}`
        });
      } else {
        history.push(`${Routes.bows}/${loadBowId}`);
      }
    }
  }, [data, error]);

  /*
    We need to pre-fetch the bow, as we need the number of alternatives to determine the
    right location (wizard or details page). This can not be simply added to the index, as
    the indexer has a 5 min delay which would mean the user would sometimes be directed to
    the wrong edit page.
    To prevent the scroll listener from logging a wrong scrollY while loading, we need to
    stop the listener (by clearing the page key) immediately (can't rely on the standard
    unmount/return of the useEffect on the BowPage)
  */
  const openBow = (bowId: string) => {
    setPageKey();
    setLoadBowId(bowId);
  };

  return (
    <>
      {loadBowId && <Disabled />}
      <Table columns={headers.length} layout={resizeColumns ? undefined : headers.map(h => getColumnWidth(h.format)).join(' ')} striped>
        {!noHeader && renderTableSectionHeaders(sections, !showClients ? h => h.key !== clientColumnKey : undefined)}
        {bows && (
          <FilterableTableHeaders
            endpoint={basePathBows}
            delimiters={getDelimiters(sections, !showClients ? h => h.key !== clientColumnKey : undefined)}
            wide
          />
        )}
        {loadStatus.loading ? <NoDataRow loadStatus={loadStatus} />
          : (
            <>
              {!loadStatus.loading && bows && bows.items?.length > 0
                ? bows?.items.map((bow, index) => <BowRow key={bow.id} bow={bow} open={openBow} index={index} />)
                : noDataDisplay ?? <NoDataRow loadStatus={loadStatus} />}
            </>
          )}
      </Table>
    </>
  );
};
